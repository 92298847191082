<template>
  <div>
   
           
    <!-- <el-carousel :height="imgHeight + 'px'">
      <el-carousel-item v-for="item in 4" :key="item">
        <img
          ref="imgHeight"
          style="width: 100%"
          src="../assets/banner.png"
          mode="widthFix"
        />
      </el-carousel-item>
    </el-carousel> -->
     <img class="banner" style="width: 100%" :src="bander" alt="" />
    <div class="box">
      <!-- 产品服务 -->
      <div
        style="
          display: flex;
          margin: 0 20px;
          margin-top: 104px;
          margin-bottom: 70px;
          align-items: center;
          justify-content: center;
        "
      >
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/left.png"
          alt=""
        />
        <div
          style="
            font-size: 34px;
            font-family: PingFang SC-Heavy, PingFang SC;
            font-weight: 800;
            color: #333333;
            margin: 0 20px;
          "
        >
          产品服务 | PRODUCTS
        </div>
        <img
          class="banner"
          style="width: 34px; height: 12px"
          src="../assets/right.png"
          alt=""
        />
      </div>
      <div style="margin-bottom: 110px;">
         <!-- @click="item.id?changenav('/dangjian',item.id):''" -->
        <img
         
          mode="widthFix"
          v-for="(item, index) in list1"
          :key="index"
          style="width: 283px; height: 283px; margin-right: 10px"
          :src="item.image"
          alt=""
        />
      </div>
      <div>
        <div v-for="(item,index) in list" :key="index" style="margin-bottom: 62px; ">
          <div
            style="
              font-size: 27px;
              font-family: PingFang SC-Heavy, PingFang SC;
              font-weight: 800;
              color: #333333;
            "
          >
           {{item.product_cate}}
          </div>
          <div style="margin: 20px 0" class="s11">
            {{item.product_blurb}}
          </div>
          <div>
            <img
              style="width: 33px; height: 33px"
              src="../assets/hou2.png"
              alt=""
			  @click="changenav('/dangjian',item.id)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import url from "../request/api";
export default {
  data() {
    return {
      imgHeight: "",
        imgurl: url.baseUrl,
        list:[],
        bander:[],
        list1:[]
    };
  },
  mounted() {
    this.chilelist()
    this.banderlist()
    this.get_banner();
    // 监听窗口变化，使得轮播图高度自适应图片高度
    window.addEventListener(
      "resize",
      () => {
        this.get_banner();
      },
      false
    );
  },
  
  methods: {
    
      banderlist(){
  this.$https
          .get(`/api/index/banner?name=product_banner`, )
          .then((res) => {
            
            console.log(res);
  // res.data.forEach((element,index) => {
  //         res.data[index] = this.imgurl+element
  //       });
            this.bander = res.data;
        });
    },
     chilelist(id){
          this.$https
        .get(`/api/product/index`)
        .then((res) => {
         console.log(res);
         res.data.product.forEach(element => {
           
           element.images=this.imgurl+element.images
         });
        
         this.list= res.data.product
          this.list1= res.data.row

         console.log(  this.list,'  this.list');
        
        });
    },
	  	changenav(path,id) {
				this.$router.push({
					path: path,
          query: {lei: id}
				})
			},
    get_banner() {
      //获取到图片数据之后
      this.$nextTick(function () {
        // 获取窗口宽度*图片的比例，定义页面初始的轮播图高度 width=1920,height=480 图片的默认宽高
        this.imgHeight = (document.body.clientWidth * 480) / 1920;
      });
    },
  },
  destroyed() {
    //切换页面后销毁监听窗口事件
    window.removeEventListener("resize", this.get_banner, false);
  },
};
</script>
<style scoped>
.box {
  width: 1200px;
  margin: 56px auto;
}
.s11 {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}
.left {
  width: 740px;
}

.left .list {
  width: 740px;
  height: 133px;
  cursor: pointer;
  margin-bottom: 48px;
}

.left .list:hover .info .title {
  color: #0049c2;
}

.left .list .img {
  width: 240px;
  height: 133px;
  overflow: hidden;
}

.left .list .img img {
  width: 240px;
}

.left .list .info {
  width: 480px;
}

.left .list .info .title {
  font-size: 20px;
  color: #222222;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.left .list .info .p {
  height: 42px;
  font-size: 16px;
  color: #666666;
  margin: 16px 0 23px 0;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.left .list .info .time {
  font-size: 14px;
  color: #999999;
}

.right {
  width: 440px;
  background: #fbfbfc;
  box-sizing: border-box;
  padding: 24px;
}

.right .titles {
  font-size: 20px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 10px;
}

.right .list {
  height: 48px;
  cursor: pointer;
}

.right .list:hover .title {
  color: #0049c2;
}

.right .list .indexs {
  width: 30px;
  font-size: 20px;
  color: #999999;
  text-align: center;
}

.right .list .title {
  width: 270px;
  font-size: 16px;
  color: #333333;
  margin: 0 15px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}

.right .list .num {
  font-size: 16px;
  color: #999999;
  margin-left: 5px;
}
</style>